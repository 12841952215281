import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { SEO, Header, Layout, PreviewCompatibleImage } from "../components";
import { Card, CardContainer } from "../ui";
import { Wrapper, Container } from "../ui/LayoutComponents";
import { linearGradients } from "../ui/variables";

import bgImage from "../img/header-images/woman-write.jpg";

export const TemoignagesMaladiesRaresPageTemplate = ({ title, posts }) => {
  return (
    <>
      <SEO title={title} />
      <Header
        title={title}
        bgImage={bgImage}
        bgPosition="center 40%"
        fallback={linearGradients.temoignages}
      />
      <Wrapper>
        <Container large>
          <CardContainer>
            {posts.map(({ node: post }) => {
              const { id, frontmatter, fields } = post;
              return (
                <Card key={id} title={frontmatter.title} text={frontmatter.description} link={fields.slug}>
                  <PreviewCompatibleImage
                    imageInfo={frontmatter.image}
                    style={{ height: "100%", borderRadius: "4px 4px 0 0" }}
                  />
                </Card>
              );
            })}
          </CardContainer>
        </Container>
      </Wrapper>
    </>
  );
};

TemoignagesMaladiesRaresPageTemplate.propTypes = {
  title: PropTypes.string,
  posts: PropTypes.array,
};

const TemoignagesMaladiesRaresPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <TemoignagesMaladiesRaresPageTemplate title={frontmatter.title} posts={posts} />
    </Layout>
  );
};

TemoignagesMaladiesRaresPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
    markdownRemark: PropTypes.object,
  }),
};

export default TemoignagesMaladiesRaresPage;

export const pageQuery = graphql`
  query TemoignagesMaladiesRaresPage($id: String!) {
    allMarkdownRemark(
      filter: {
        fields: { slug: { regex: "/temoignages-maladies-rares/", ne: "/temoignages-maladies-rares/" } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 700, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
    }
  }
`;
